import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CapturumButtonModule } from '@capturum/ui/button';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { PageLayoutComponent } from '@shared/page-layout/page-layout.component';

@NgModule({
  declarations: [PageLayoutComponent],
  imports: [CommonModule, NgxPermissionsModule, CapturumButtonModule, TranslateModule, CapturumSharedModule],
  exports: [NgxPermissionsModule, TranslateModule, CapturumSharedModule, PageLayoutComponent],
})
export class SharedModule {}
